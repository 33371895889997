import '../App.css'
import Navigation from '../componets/Navigation'
import Footer from '../componets/Footer'
import './repeat.css'

function Ofiarodawcy(){
    return (
        <>
            <Navigation />
            <div className='article'>
                <div className='title'>OFIARA  NA POTRZEBY  KOŚCIOŁA GRUDZIEŃ  2024</div>
                <table>
                    <tr><td>1</td><td>M.M.Brodzińscy</td><td>Łowiczki Jodłowa 28</td><td>100zł</td></tr>
                    <tr><td>2</td><td>Brusik</td><td>Łowiczki Graniczna</td><td>400zł</td></tr>
                    <tr><td>3</td><td>Brzeźniak</td><td>Łowiczki Graniczna 38</td><td>100zł</td></tr>
                    <tr><td>4</td><td>Czopek</td><td>Łowiczki Centralna 216</td><td>200zł</td></tr>
                    <tr><td>5</td><td>Jan Gliwa</td><td>Łowiczki Centralna 8</td><td>200zł</td></tr>
                    <tr><td>6</td><td>A.B.Głąb</td><td>Łowiczki</td><td>100zł</td></tr>
                    <tr><td>7</td><td>J.J.Głąb</td><td>Łowiczki</td><td>100zł</td></tr>
                    <tr><td>8</td><td>S.A.Głąb</td><td>Łowiczki</td><td>100zł</td></tr>
                    <tr><td>9</td><td>Maria Głąb</td><td>Łowiczki Centralna 80</td><td>50zł</td></tr>
                    <tr><td>10</td><td>A.D.Jezierscy</td><td>Łowiczki</td><td>500zł</td></tr>
                    <tr><td>11</td><td>B.B.Jezierscy</td><td>Łowiczki Centralna 194</td><td>50zł</td></tr>
                    <tr><td>12</td><td>Zbigniew Korczyk</td><td>Łowiczki Centralna 136</td><td>200zł</td></tr>
                    <tr><td>13</td><td>U.J.Kreczmer</td><td>Łowiczki</td><td>100zł</td></tr>
                    <tr><td>14</td><td>Dorota Leszek Majkut</td><td>Łowiczki</td><td>100zł</td></tr>
                    <tr><td>15</td><td>J.A.Majkut</td><td>Łowiczki Centralna 85</td><td>200zł</td></tr>
                    <tr><td>16</td><td>W.A.Matla</td><td>Łowiczki Jodłowa 15</td><td>100zł</td></tr>
                    <tr><td>17</td><td>M.J.Seremet</td><td>Łowiczki Kanada</td><td>200zł</td></tr>
                    <tr><td>18</td><td>B.A.Wojtaszek</td><td>Łowiczki</td><td>100zł</td></tr>
                    <tr><td>19</td><td>Jerzy Wójcik</td><td>Łowiczki Cyprysowa 15</td><td>100zł</td></tr>
                    <tr><td>20</td><td>Bronisława Antecka</td><td>Andrychowska 113</td><td>100zł</td></tr>
                    <tr><td>21</td><td>J.A.Anteccy</td><td>Kasztanowa 109</td><td>100zł</td></tr>
                    <tr><td>22</td><td>M.L.Anteccy</td><td>Andrychowska 13</td><td>100zł</td></tr>
                    <tr><td>23</td><td>Rozalia Antecka</td><td>Kasztanowa 116</td><td>100zł</td></tr>
                    <tr><td>24</td><td>Augustyn</td><td>Modrzewiowa</td><td>50zł</td></tr>
                    <tr><td>25</td><td>Bałys</td><td>Andrychowska 346</td><td>100zł</td></tr>
                    <tr><td>26</td><td>Bernaś, Momot</td><td>Akacjowa 31</td><td>100zł</td></tr>
                    <tr><td>27</td><td>Mieczysław Bernaś</td><td>Modrzewiowa 47</td><td>100zł</td></tr>
                    <tr><td>28</td><td>Maria Stanisław Boba</td><td>Andrychowska 352</td><td>100zł</td></tr>
                    <tr><td>29</td><td>M.W.Brusik</td><td>Andrychowska 280</td><td>100zł</td></tr>
                    <tr><td>30</td><td>R.M.Brusik</td><td>Andrychowska 280</td><td>100zł</td></tr>
                    <tr><td>31</td><td>M.G.Całus</td><td>-</td><td>200zł</td></tr>
                    <tr><td>32</td><td>L.S.Czopek</td><td>Andrychowska 262</td><td>100zł</td></tr>
                    <tr><td>33</td><td>K.J.Domider</td><td>Andrychowska 216</td><td>100zł</td></tr>
                    <tr><td>34</td><td>Frączek</td><td>Andrychowska 265</td><td>100zł</td></tr>
                    <tr><td>35</td><td>Frączek, Sanak</td><td>Andrychowska 161</td><td>100zł</td></tr>
                    <tr><td>36</td><td>Anna Frączek</td><td>Wrzosowa 22</td><td>100zł</td></tr>
                    <tr><td>37</td><td>B.Frączek</td><td>Modrzewiowa 2</td><td>100zł</td></tr>
                    <tr><td>38</td><td>D.Frączek</td><td>Kasztanowa 74</td><td>100zł</td></tr>
                    <tr><td>39</td><td>I.S.Frączek</td><td>Andrychowska 163</td><td>100zł</td></tr>
                    <tr><td>40</td><td>Krystyna Andrzej Frączek</td><td>Lipowa 44</td><td>100zł</td></tr>
                    <tr><td>41</td><td>M.R.Frączek</td><td>Andrychowska 227</td><td>100zł</td></tr>
                    <tr><td>42</td><td>Marcin Gagracz</td><td>Akacjowa 96</td><td>100zł</td></tr>
                    <tr><td>43</td><td>W.Z.Gąsiorek</td><td>Andrychowska 374</td><td>100zł</td></tr>
                    <tr><td>44</td><td>Józef Głąb</td><td>Modrzewiowa 43</td><td>200zł</td></tr>
                    <tr><td>45</td><td>W.M.Gorzkowscy</td><td>-</td><td>100zł</td></tr>
                    <tr><td>46</td><td>Górecki, Kobiałka</td><td>-</td><td>200zł</td></tr>
                    <tr><td>47</td><td>Alojzy Górecki</td><td>-</td><td>200zł</td></tr>
                    <tr><td>48</td><td>Jan Górecki</td><td>-</td><td>200zł</td></tr>
                    <tr><td>49</td><td>E.F.Góreccy</td><td>Akacjowa 44</td><td>100zł</td></tr>
                    <tr><td>50</td><td>Hałatek</td><td>Lipowa</td><td>100zł</td></tr>
                    <tr>
            <td>51</td>
            <td>R.L. Janik</td>
            <td>Pagórkowa 19</td>
            <td>100zł</td>
        </tr>
        <tr>
            <td>52</td>
            <td>Piotr Marcin Jureccy</td>
            <td>Andrychowska 2</td>
            <td>100zł</td>
        </tr>
        <tr>
            <td>53</td>
            <td>M.P. Kaleńscy</td>
            <td>Andrychowska 280</td>
            <td>400zł</td>
        </tr>
        <tr>
            <td>54</td>
            <td>Kalman</td>
            <td>Kasztanowa 45</td>
            <td>100zł</td>
        </tr>
        <tr>
            <td>55</td>
            <td>H.Kiszczak</td>
            <td>Bukowiecka 42</td>
            <td>100zł</td>
        </tr>
        <tr>
            <td>56</td>
            <td>S.A.Kolasa</td>
            <td>Andrychowska 173</td>
            <td>100zł</td>
        </tr>
        <tr>
            <td>57</td>
            <td>Kołodziej</td>
            <td>Lipowa 59</td>
            <td>100zł</td>
        </tr>
        <tr>
            <td>58</td>
            <td>Kozak</td>
            <td>Kasztanowa 5</td>
            <td>100zł</td>
        </tr>
        <tr>
            <td>59</td>
            <td>E.P. Kozak</td>
            <td>Andrychowska 233</td>
            <td>100zł</td>
        </tr>
        <tr>
            <td>60</td>
            <td>Maria Kozak</td>
            <td>Andrychowska 208</td>
            <td>100zł</td>
        </tr>
        <tr>
            <td>61</td>
            <td>A.K. Krawczyk</td>
            <td>Akacjowa</td>
            <td>100zł</td>
        </tr>
        <tr>
            <td>62</td>
            <td>M.K. Krawczyk, J.Cabaj</td>
            <td></td>
            <td>300zł</td>
        </tr>
        <tr>
            <td>63</td>
            <td>A.D. Krzyścin</td>
            <td>Pagórkowa 19</td>
            <td>100zł</td>
        </tr>
        <tr>
            <td>64</td>
            <td>D.M. Krzyścin</td>
            <td>Lipowa 18</td>
            <td>100zł</td>
        </tr>
        <tr>
            <td>65</td>
            <td>R.Ł. Kwaczała</td>
            <td>Lipowa 67</td>
            <td>500zł</td>
        </tr>
        <tr>
            <td>66</td>
            <td>T.J. Kusak</td>
            <td>Andrychowska 238</td>
            <td>50zł</td>
        </tr>
        <tr>
            <td>67</td>
            <td>Jerzy Kuska</td>
            <td>Lipowa 73</td>
            <td>100zł</td>
        </tr>
        <tr>
            <td>68</td>
            <td>Stanisław Kuska</td>
            <td>Kasztanowa 4</td>
            <td>50zł</td>
        </tr>
        <tr>
            <td>69</td>
            <td>Makuch</td>
            <td>Andrychowska 134</td>
            <td>100zł</td>
        </tr>
        <tr>
            <td>70</td>
            <td>Makuch</td>
            <td>Andrychowska 288</td>
            <td>100zł</td>
        </tr>
        <tr>
            <td>71</td>
            <td>Józefa Makuch</td>
            <td>Lipowa 25</td>
            <td>100zł</td>
        </tr>
        <tr>
            <td>72</td>
            <td>Józef Makuch</td>
            <td>Akacjowa</td>
            <td>100zł</td>
        </tr>
        <tr>
            <td>73</td>
            <td>Ludwik Makuch</td>
            <td>Andrychowska 316</td>
            <td>100zł</td>
        </tr>
        <tr>
            <td>74</td>
            <td>Sebastian Makuch</td>
            <td></td>
            <td>300zł</td>
        </tr>
        <tr>
            <td>75</td>
            <td>Waldemar Makuch</td>
            <td>Akacjowa</td>
            <td>100zł</td>
        </tr>
        <tr>
            <td>76</td>
            <td>Antoni Mamoń</td>
            <td>Andrychowska 131</td>
            <td>50zł</td>
        </tr>
        <tr>
            <td>77</td>
            <td>Marsowicz</td>
            <td>Andrychowska 34</td>
            <td>100zł</td>
        </tr>
        <tr>
            <td>78</td>
            <td>Marsowicz</td>
            <td>Andrychowska 155</td>
            <td>50zł</td>
        </tr>
        <tr>
            <td>79</td>
            <td>Michalska</td>
            <td></td>
            <td>100zł</td>
        </tr>
        <tr>
            <td>80</td>
            <td>Stanisław Młynarczyk</td>
            <td>Akacjowa 56</td>
            <td>100zł</td>
        </tr>
        <tr>
            <td>81</td>
            <td>Niedziela</td>
            <td>Wrzosowa</td>
            <td>400zł</td>
        </tr>
        <tr>
            <td>82</td>
            <td>Marek Beata Nowak</td>
            <td>Wrzosowa 13</td>
            <td>200zł</td>
        </tr>
        <tr>
            <td>83</td>
            <td>Marianna Nowak</td>
            <td>Modrzewiowa 34</td>
            <td>50zł</td>
        </tr>
        <tr>
            <td>84</td>
            <td>Wiktoria Noworyta</td>
            <td></td>
            <td>50zł</td>
        </tr>
        <tr>
            <td>85</td>
            <td>E.Nykiel</td>
            <td>Lipowa 72</td>
            <td>100zł</td>
        </tr>
        <tr>
            <td>86</td>
            <td>W.S.Ortman</td>
            <td>Kasztanowa 46</td>
            <td>200zł</td>
        </tr>
        <tr>
            <td>87</td>
            <td>Pacyga</td>
            <td></td>
            <td>100zł</td>
        </tr>
        <tr>
            <td>88</td>
            <td>D.K.Pieczonka</td>
            <td>Andrychowska 171</td>
            <td>100zł</td>
        </tr>
        <tr>
            <td>89</td>
            <td>Płonka</td>
            <td>Andrychowska 299</td>
            <td>100zł</td>
        </tr>
        <tr>
            <td>90</td>
            <td>A.W.Płonka</td>
            <td>Kościelna 6</td>
            <td>100zł</td>
        </tr>
        <tr>
            <td>91</td>
            <td>S.W.Poręba</td>
            <td></td>
            <td>100zł</td>
        </tr>
        <tr>
            <td>92</td>
            <td>B.R.</td>
            <td>Wrzosowa 17</td>
            <td>50zł</td>
        </tr>
        <tr>
            <td>93</td>
            <td>Rozalia Rola</td>
            <td>Andrychowska 225</td>
            <td>100zł</td>
        </tr>
        <tr>
            <td>94</td>
            <td>Beata Szczęśniak</td>
            <td>Lipowa 61A</td>
            <td>500zł</td>
        </tr>
        <tr>
            <td>95</td>
            <td>A.Z.Szklarscy</td>
            <td>Andrychowska 52</td>
            <td>100zł</td>
        </tr>
        <tr>
            <td>96</td>
            <td>B.A.Szymańscy</td>
            <td>Andrychowska 410</td>
            <td>50zł</td>
        </tr>
        <tr>
            <td>97</td>
            <td>Śliwińska</td>
            <td>Kasztanowa 112</td>
            <td>50zł</td>
        </tr>
        <tr>
            <td>98</td>
            <td>A.A.Środa</td>
            <td>Akacjowa 77</td>
            <td>100zł</td>
        </tr>
        <tr>
            <td>99</td>
            <td>Tysa</td>
            <td>Modrzewiowa 12</td>
            <td>100zł</td>
        </tr>
        <tr>
            <td>100</td>
            <td>Jarosław Wanat</td>
            <td>Lipowa 41</td>
            <td>100zł</td>
        </tr>
        <tr>
                <td>101</td>
                <td>Iwona Adam Wasilewscy</td>
                <td>Pagórkowa 10</td>
                <td>100zł</td>
            </tr>
            <tr>
                <td>102</td>
                <td>M.W.Wasztyl</td>
                <td></td>
                <td>100zł</td>
            </tr>
            <tr>
                <td>103</td>
                <td>Władysława Ważydrąg</td>
                <td>Źródlana</td>
                <td>200zł</td>
            </tr>
            <tr>
                <td>104</td>
                <td>Anna Wilgocka</td>
                <td>Andrychowska 103</td>
                <td>100zł</td>
            </tr>
            <tr>
                <td>105</td>
                <td>Łukasz Wiśniecki</td>
                <td>Lipowa 43</td>
                <td>100zł</td>
            </tr>
            <tr>
                <td>106</td>
                <td>A.Włoszek</td>
                <td></td>
                <td>50zł</td>
            </tr>
            <tr>
                <td>107</td>
                <td>Wnętrzak</td>
                <td></td>
                <td>200zł</td>
            </tr>
            <tr>
                <td>108</td>
                <td>Wojtala</td>
                <td>Andrychowska 172</td>
                <td>50zł</td>
            </tr>
            <tr>
                <td>109</td>
                <td>M.A.Wróbel</td>
                <td>Węglarzówka 2a</td>
                <td>100zł</td>
            </tr>
            <tr>
                <td>110</td>
                <td>W.D.Ubik</td>
                <td>Wrzosowa 4</td>
                <td>100zł</td>
            </tr>
            <tr>
                <td>111</td>
                <td>Jolanta Ulczok</td>
                <td>Kasztanowa 30</td>
                <td>100zł</td>
            </tr>
            <tr>
                <td>112</td>
                <td>K.Z.</td>
                <td>Pagórkowa</td>
                <td>200zł</td>
            </tr>
            <tr>
                <td>113</td>
                <td>A.Zajas</td>
                <td>Pagórkowa</td>
                <td>100zł</td>
            </tr>
            <tr>
                <td>114</td>
                <td>A.C.Zając</td>
                <td>Andrychowska 111</td>
                <td>100zł</td>
            </tr>
            <tr>
                <td>115</td>
                <td>Czesław Zając</td>
                <td>Kasztanowa 123</td>
                <td>100zł</td>
            </tr>
            <tr>
                <td>116</td>
                <td>J.Zając</td>
                <td>Andrychowska 320</td>
                <td>100zł</td>
            </tr>
            <tr>
                <td>117</td>
                <td>J.K.Zając</td>
                <td>Andrychowska 101</td>
                <td>100zł</td>
            </tr>
            <tr>
                <td>118</td>
                <td>M.B.Zając</td>
                <td>Andrychowska 60</td>
                <td>300zł</td>
            </tr>
            <tr>
                <td>119</td>
                <td>W.Zarzycki</td>
                <td></td>
                <td>100zł</td>
            </tr>
            <tr>
                <td>120</td>
                <td>Maria Zdżalik</td>
                <td>Wrzosowa 25</td>
                <td>50zł</td>
            </tr>
            <tr>
                <td>121</td>
                <td>A.Ł.Zięba</td>
                <td>Andrychowska 351</td>
                <td>200zł</td>
            </tr>
            <tr>
                <td>122</td>
                <td>J.C.Zygmunt</td>
                <td>Lipowa 15</td>
                <td>100zł</td>
            </tr>
            <tr>
                <td>123</td>
                <td>Ewa Zbigniew Żmuda</td>
                <td>Akacjowa 26</td>
                <td>600zł</td>
            </tr>
            <tr>
                <td>124</td>
                <td></td>
                <td>Andrychowska 270</td>
                <td>50zł</td>
            </tr>
            <tr>
                <td>125</td>
                <td></td>
                <td>Andrychowska 338</td>
                <td>100zł</td>
            </tr>
            <tr>
                <td>126</td>
                <td></td>
                <td>Kościelna 8</td>
                <td>200zł</td>
            </tr>
            <tr>
                <td>127</td>
                <td></td>
                <td>Lipowa 74</td>
                <td>100zł</td>
            </tr>
            <tr>
                <td>128</td>
                <td>Rodzice Bierzmowanych</td>
                <td></td>
                <td>3500zł</td>
            </tr>
            <tr>
                <td>129</td>
                <td>Anonimowo</td>
                <td></td>
                <td>1000zł</td>
            </tr>
            <tr>
                <td>130</td>
                <td></td>
                <td>Andrychowska 32</td>
                <td>100zł</td>
            </tr>
            <tr>
                <td>131</td>
                <td></td>
                <td>Andrychowska 164</td>
                <td>200zł</td>
            </tr>
            <tr>
                <td>132</td>
                <td></td>
                <td>Andrychowska 245</td>
                <td>100zł</td>
            </tr>
            <tr>
                <td>133</td>
                <td></td>
                <td>Andrychowska 260</td>
                <td>100zł</td>
            </tr>
            <tr>
                <td>134</td>
                <td></td>
                <td>Andrychowska 27</td>
                <td>50zł</td>
            </tr>
            <tr>
                <td>135</td>
                <td></td>
                <td>Kasztanowa 66</td>
                <td>500zł</td>
            </tr>
            <tr>
                <td>136</td>
                <td></td>
                <td>Kasztanowa 77</td>
                <td>100zł</td>
            </tr>
            <tr>
                <td>137</td>
                <td></td>
                <td>Wrzosowa 23</td>
                <td>100zł</td>
            </tr>
            <tr>
                <td>138</td>
                <td></td>
                <td>Wrzosowa 27</td>
                <td>50zł</td>
            </tr>
                </table>
            </div>
            <div className='article'>
                <div className='title'>OFIARA NA OGRZEWANIA I ENERGIĘ 2025</div>
                <table>
                    <tr><td>1</td><td>Brzeźniak</td><td>Łowiczki Graniczna 38</td><td>50zł</td></tr>
                    <tr><td>2</td><td>Anna Artur Dąbrowscy</td><td>Łowiczki Centralna 228</td><td>50zł</td></tr>
                    <tr><td>3</td><td>Czesław Dąbrowski</td><td>Łowiczki Centralna 228</td><td>50zł</td></tr>
                    <tr><td>4</td><td>Dorota Dabrowska</td><td>Łowiczki</td><td>50zł</td></tr>
                    <tr><td>5</td><td>Halina Marek Dąbrowscy</td><td>Łowiczki Centralna 228</td><td>100zł</td></tr>
                    <tr><td>6</td><td>Józefa Domagała</td><td>Łowiczki</td><td>50zł</td></tr>
                    <tr><td>7</td><td>J.J.Głąb</td><td>Łowiczki</td><td>50zł</td></tr>
                    <tr><td>8</td><td>Dorota Leszek Majkut</td><td>Łowiczki</td><td>100zł</td></tr>
                    <tr><td>9</td><td>Matyjasik, Śpiewla</td><td>Łowiczki</td><td>50zł</td></tr>
                    <tr><td>10</td><td>J.J.Sroczyńscy</td><td>Łowiczki Jodłowa 9</td><td>100zł</td></tr>
                    <tr><td>11</td><td>S.K.Walichrad</td><td>Łowiczki</td><td>100zł</td></tr>
                    <tr><td>12</td><td>Władysława Zając</td><td>Łowiczki Jodłowa 10</td><td>100zł</td></tr>
                    <tr><td>13</td><td>H.S.Zemła</td><td>Łowiczki</td><td>200zł</td></tr>
                    <tr><td>14</td><td>M.L.Anteccy</td><td></td><td>100zł</td></tr>
                    <tr><td>15</td><td>Bernaś, Momot</td><td>Akacjowa 31</td><td>100zł</td></tr>
                    <tr><td>16</td><td>M.S.Boba</td><td>Andrychowska 352</td><td>200zł</td></tr>
                    <tr><td>17</td><td>Brusik</td><td>Andrychowska 280</td><td>100zł</td></tr>
                    <tr><td>18</td><td>L.S.Czopek</td><td>Andrychowska 262</td><td>100zł</td></tr>
                    <tr><td>19</td><td>Dziedzic</td><td>Lipowa</td><td>100zł</td></tr>
                    <tr><td>20</td><td>Frączek</td><td>Andrychowska 265</td><td>50zł</td></tr>
                    <tr><td>21</td><td>Frączek</td><td>Andrychowska 362</td><td>50zł</td></tr>
                    <tr><td>22</td><td>T.K.Frączek</td><td>Andrychowska 297</td><td>100zł</td></tr>
                    <tr><td>23</td><td>Krystyna Gagracz</td><td>Kasztanowa 130</td><td>50zł</td></tr>
                    <tr><td>24</td><td>Marcin Gagracz</td><td>Akacjowa 96</td><td>50zł</td></tr>
                    <tr><td>25</td><td>D.K.Głąb</td><td>Źródlana 1</td><td>50zł</td></tr>
                    <tr><td>26</td><td>Józef Głąb</td><td>Modrzewiowa</td><td>100zł</td></tr>
                    <tr><td>27</td><td>J.N.Głąb</td><td>Modrzewiowa</td><td>50zł</td></tr>
                    <tr><td>28</td><td>Górecki, Kobiałka</td><td></td><td>150zł</td></tr>
                    <tr><td>29</td><td>Hałat, Gryska</td><td>Andrychowska 305</td><td>100zł</td></tr>
                    <tr><td>30</td><td>Hałatek</td><td>Lipowa</td><td>100zł</td></tr>
                    <tr><td>31</td><td>Piotr Marcin Jurecki</td><td>Andrychowska 2</td><td>100zł</td></tr>
                    <tr><td>32</td><td>Knutel, Frączek</td><td>Modrzewiowa 3</td><td>100zł</td></tr>
                    <tr><td>33</td><td>Krzyścin</td><td>Pagórkowa 19</td><td>50zł</td></tr>
                    <tr><td>34</td><td>Józef Makuch</td><td>Akacjowa</td><td>50zł</td></tr>
                    <tr><td>35</td><td>Waldemar Makuch</td><td>Akacjowa</td><td>50zł</td></tr>
                    <tr><td>36</td><td>D.K.Oleksy</td><td>Pagórkowa 3</td><td>50zł</td></tr>
                    <tr><td>37</td><td>Jan Anna Płachta</td><td>Pagórkowa 10</td><td>100zł</td></tr>
                    <tr><td>38</td><td>S.W.Poręba</td><td></td><td>50zł</td></tr>
                    <tr><td>39</td><td>Rał</td><td>Andrychowska 27</td><td>100zł</td></tr>
                    <tr><td>40</td><td>I.S.</td><td></td><td>100zł</td></tr>
                    <tr><td>41</td><td>B.S.Szczęśniak</td><td>Lipowa 61a</td><td>100zł</td></tr>
                    <tr><td>42</td><td>Stanisława Kazimierz Ubik</td><td>Wrzosowa 2</td><td>100zł</td></tr>
                    <tr><td>43</td><td>Wolas</td><td>Wrzosowa 19</td><td>50zł</td></tr>
                    <tr><td>44</td><td>A.C.Zajac</td><td>Andrychowska 111</td><td>100zł</td></tr>
                    <tr><td>45</td><td></td><td>Akacjowa 24</td><td>100zł</td></tr>
                    <tr><td>46</td><td></td><td>Andrychowska 338</td><td>50zł</td></tr>
                    <tr><td>47</td><td></td><td>Kasztanowa 10</td><td>100zł</td></tr>
                    <tr><td>48</td><td></td><td>Kasztanowa 42</td><td>150zł</td></tr>
                    <tr><td>49</td><td>Anonimowo</td><td></td><td>100zł</td></tr>
                </table>
            </div>
            <Footer />
        </>
)}

export default Ofiarodawcy