import Navigation from '../componets/Navigation'
import Footer from '../componets/Footer'

function IntencjeLowiczki() {
    return(
        <>
            <Navigation />
            <div className='article'>
                <div className='title'>Intecje Łowiczki</div>
                <ul>
                    <li>Środa 1.01.2025 ++ Mieczysław Józefa Lucyna Żydek</li>
                    <li>Piątek 3.01 ++ Eugeniusz Stanisława Nykiel, rodzice, Jan s.</li>
                    <li>Niedziela 5.01 ++ Adam Katarzyna Skawińscy, Anna c.</li>
                    <li>Poniedziałek 6.01 + Mieczysław Czopek</li>
                    <li>Niedziela 12.01 + Cecylia Czopek rocz.</li>
                    <li>Niedziela 19.01 ++ Franciszek Helena Wójcik, Stanisława c.</li>
                    <li>Niedziela 26.01 ++ Władysław Jadwiga Halbina</li>
                    <li>Niedziela 2.02++ Henryk Janina Wiecheć, rodzice</li>
                    <li>Piątek 7.02 + Anna Dąbrowska (od córki Ewy z mężem)</li>
                    <li>Niedziela 9.02 ++ Kazimierz Władysława Zajas, rodzice</li>

                </ul>
            </div>
            <Footer />
        </>
    )
}

export default IntencjeLowiczki