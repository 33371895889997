import Navigation from '../componets/Navigation'
import Footer from '../componets/Footer'

function Roze() {
    return(
        <>
            <Navigation />
            <div className="flexroza">
                <div className="elementroza">
                    <div className="title">I Róża Różańcowa</div>
                    <div className="subtitle">Patron: Bł. Anna Katarzyna Emmerich</div>
                    <ul>
                        <li>Zarzycka Stanisława - Zelator</li>
                        <li>Frączek Zofia</li>
                        <li>Makuch Anna</li>
                        <li>Nykiel Elżbieta</li>
                        <li>Knutel Zofia</li>
                        <li>Kuska Stanisława</li>
                        <li>Marsowicz Maria</li>
                        <li>Kozłowska Łucja</li>
                        <li>Zając Aleksandra</li>
                        <li>Łubiasz Stanisława</li>
                        <li>Monika Całus</li>
                        <li>Michalska Alicja</li>
                        <li>Mikuła Krystyna</li>
                        <li>Skawina Aleksandra</li>
                        <li>Ważydrąg Władysława</li>
                        <li>Nowak Irena</li>
                        <li>Kozioł Kazimiera</li>
                        <li>Zając Stanisława</li>
                        <li>Antecka Bronisława</li>
                        <li>Gryska Zofia</li>
                    </ul>
                </div>
                <div className="elementroza">
                    <div className="title">II Róża Różańcowa</div>
                    <div className="subtitle">Patron: - </div>
                    <ul>
                        <li>Wanda Adamczyk</li>
                        <li>Irena Brusik</li>
                        <li>Anna Wojtala</li>
                        <li>Marianna Frączek</li>
                        <li>Stanisława Górecka</li>
                        <li>Stanisława Jakubaszek</li>
                        <li>Danuta Jurecka</li>
                        <li>Anna Kalman</li>
                        <li>Kazimiera Kalman</li>
                        <li>Maria Kolasa</li>
                        <li>Zofia Kozieł</li>
                        <li>Anna Kostucha</li>
                        <li>Zofia Klęczar</li>
                        <li>Bogusława Kuwik</li>
                        <li>Maria Kuśpik</li>
                        <li>Małgorzata Kuska</li>
                        <li>Władysława Kubas</li>
                        <li>Anna Pietras</li>
                        <li>Krystyna Zając</li>
                        <li>Maria Zdżalik</li>
                    </ul>
                </div>
                <div className="elementroza">
                    <div className="title">III Róża Różańcowa</div>
                    <div className="subtitle">Patron: Św Ojciec Pio</div>
                        <ul>
                        <li>Agnieszka Gagracz - Zelator</li>
                        <li>Barbara Bulek</li>
                        <li>Magdalena Bytnar</li>
                        <li>Bernadetta Cholewka</li>
                        <li>Agnieszka Frączek</li>
                        <li>Teresa Frączek</li>
                        <li>Małgorzata Krzaczyńska</li>
                        <li>Renata Kobylańska</li>
                        <li>Grażyna Makuch</li>
                        <li>Justyna Makuch</li>
                        <li>Grażyna Marsowicz</li>
                        <li>Renata Noga</li>
                        <li>Danuta Nowak</li>
                        <li>Stanisława Poręba</li>
                        <li>Edyta Sanak</li>
                        <li>Małgorzata Stolarczyk</li>
                        <li>Sabina Stolarczyk</li>
                        <li>Bogusława Szymonik</li>
                        <li>Małogrzata Wasztyl</li>
                        <li>Jolanta Zając</li>
                    </ul>
                </div>
                <div className="elementroza">
                    <div className="title">IV Róża Różańcowa</div>
                    <div className="subtitle">Patron: Św Rita</div>
                    <ul>
                        <li>Justyna Makuch - Zelator</li>
                        <li>Agnieszka Cabaj</li>
                        <li>Aneta Cygan</li>
                        <li>Anna Frączek</li>
                        <li>Joanna Frączek</li>
                        <li>Krystyna Frączek</li>
                        <li>Barbara Głąb</li>
                        <li>Maria Głąb</li>
                        <li>Małgorzata Gorzkowska</li>
                        <li>Aleksandra Jurecka</li>
                        <li>Ewa Kozak</li>
                        <li>Małgorzata Makuch</li>
                        <li>Jolanta Nowak</li>
                        <li>Iwona Staszczak</li>
                        <li>Renata Siwek</li>
                        <li>Aleksandra Szymańska</li>
                        <li>Renata Śliwa</li>
                        <li>Faustyna Talaga</li>
                        <li>Teresa Zielińska</li>
                        <li>Urszula Żabińska</li>
                    </ul>
                </div>
                <div className="elementroza">
                    <div className="title">V Róża Różańcowa</div>
                    <div className="subtitle">Patron: - Bł. Kardynał Stefan Wyszyński</div>
                    <ul>
                        <li>Marcin Gagracz - Zelator</li>
                        <li>Całus Gabriel</li>
                        <li>Ryszard Cholewka</li>
                        <li>Tadeusz Frączek</li>
                        <li>Piotr Jurecki</li>
                        <li>Łukasz Zięba</li>
                        <li>Władysław Kostucha</li>
                        <li>Andrzej Majka</li>
                        <li>Józef Makuch</li>
                        <li>Marek Makuch</li>
                        <li>Sebastian Makuch</li>
                        <li>Waldermar Makuch</li>
                        <li>Bogdan Staszczak</li>
                        <li>Jan Stolarczyk</li>
                        <li>Krzysztof Stolarczyk</li>
                        <li>Łukasz Tobiczyk</li>
                        <li>Piotr Bernaś</li>
                        <li>Stanisław Witek</li>
                        <li>Mieczysław Żabiński</li>
                        <li>Przemysł Żabiński</li>
                    </ul>
                </div>
                <div className="elementroza">
                    <div className="title">VI Róża Różańcowa</div>
                    <div className="subtitle">Patron: Św Stanisław Kostka</div>
                    <ul>
                        <li>Anna Górecka</li>
                        <li>Beata Górecka</li>
                        <li>Elżbieta Węglarz</li>
                        <li>Ewa Frączek</li>
                        <li>Aldona Obstarczyk</li>
                        <li>Monika Osowska</li>
                        <li>Maria Siekanka</li>
                        <li>Małgorzata Domasik</li>
                        <li>Klaudia Górecka</li>
                        <li>Renata Noga</li>
                        <li>Renata Kobylańska</li>
                        <li>Justyna Makuch</li>
                        <li>Waldemar Makuch</li>
                        <li>Justyna Makuch</li>
                        <li>Józef Makuch</li>
                        <li>Grażyna Makuch</li>
                        <li>Izabela Momot-Bernaś</li>
                        <li>Marcin Gagracz</li>
                        <li>Jacek Gagracz</li>
                        <li>Marta Gagracz</li>
                    </ul>
                </div>
                <div className="elementroza">
                    <div className="title">VII Róża Różańcowa</div>
                    <div className="subtitle">Patron: Św Jan Paweł II</div>
                    <ul>
                        <li>Iwona Staszczak - Zelator</li>
                        <li>Beata Nocula</li>
                        <li>Małgorzata Gorzkowska</li>
                        <li>Aneta Zięba</li>
                        <li>Renata Kocjan</li>
                        <li>Dorota Witek</li>
                        <li>Bogusław Staszczak</li>
                        <li>Agnieszka Domider</li>
                        <li>Krystyna Gagracz</li>
                        <li>Iwona Gagracz</li>
                        <li>Izabela Zając</li>
                        <li>Małgorzata Hałat</li>
                        <li>Jadwiga Knutel</li>
                        <li>Agnieszka Knutel</li>
                        <li>Małgorzata Stuglik</li>
                        <li>Beata Górecka</li>
                        <li>Grażyna Marsowicz</li>
                        <li>Sylwia Cygan</li>
                        <li>Edyta Śliwińska</li>
                        <li>Ewelina Jarosz</li>
                    </ul>
                </div>
                <div className="elementroza">
                    <div className="title">Łowiczki Roża Różańcowa</div>
                    <div className="subtitle">Patron:</div>
                    <ul>
                        <li>Janina Majkut</li>
                        <li>Władysława Bies</li>
                        <li>Anna Czopek</li>
                        <li>Aleksandra Oleś</li>
                        <li>Zofia Czopek</li>
                        <li>Halina Dąbrowksa</li>
                        <li>Maria Gliwa</li>
                        <li>Józefa Głąb</li>
                        <li>Lidia Grudek</li>
                        <li>Helena Jurkowska</li>
                        <li>Zofia Mamoń</li>
                        <li>Józefa Mięka</li>
                        <li>Kazimiera Pardygał</li>
                        <li>Anna Pawłowska</li>
                        <li>Krystyna Skibińska</li>
                        <li>Irena Serment</li>
                        <li>Ludwika Sermak</li>
                        <li>Bernadeta Wojtaszek</li>
                        <li>Wójcik Rozalia</li>
                        <li>Franciszka Zięba</li>
                    </ul>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Roze