import Navigation from '../componets/Navigation'
import Footer from '../componets/Footer'

function Sakramenty() {
    return(
        <>
            <Navigation />
            <div className='article'>
                <div className='title'>CHRZEST</div>
                <div className='subtitle'>W celu załatwienia formalności związanych z chrztem dziecka należy:</div>
                <p>Zgłosić się w kancelarii parafialnej i przedstawić dokumenty:</p>
                <ul>
                    <li>Akt urodzenia dziecka z USC</li>
                    <li>Podać dokładne dane rodziców dziecka ( w tym datę ślubu)</li>
                    <li>Podać dane rodziców chrzestnych, (jeżeli są nimi osoby z poza parafii wymagane jest zaświadczenie 
                    od księdza z parafii chrzestnych stwierdzające, że dana osoba może być chrzestnym(ą) dziecka</li>
                </ul>
                <p>Chrzestnymi mogą być: </p>
                <ul>
                    <li>osoby, które ukończyły 16 rok życia i są po bierzmowaniu</li>
                    <li>osoby wierzące i praktykujące</li>
                    <li>osoby stanu wolnego, lub żyjące w sakramentalnym małżeństwie</li>
                </ul>
                <p>Sakramentu chrztu świętego udzielamy w każdą II i IV niedzielę miesiąca na mszy św. o godz. 11.00.</p>

                <br /><br /><br /><br />

                <div className='title'>POGRZEB</div>
                <div className='subtitle'>W celu załatwienia formalności związanych z pogrzebem należy:</div>
                <p>Zgłosić się w kancelarii parafialnej i przedstawić następujące dokumenty:</p>
                <ul>
                    <li>Akt zgonu wydany przez USC</li>
                    <li>Kartę zgonu wydaną przez lekarza</li>
                    <li>Zaświadczenie o przyjęciu sakramentów świętych, jeśli zgon nastąpił poza parafią</li>
                </ul>
                <p>Należy również z księdzem w pierwszej kolejności uzgodnić termin pogrzebu.</p>

                <br /><br /><br /><br />

                <div className='title'>MAŁŻEŃSTWO</div>
                <div className='subtitle'>W celu załatwienia formalności związanych ze ślubem należy:</div>
                <ol>
                    <li>Zgłosić się w kancelarii parafialnej nie później niż 3 miesiące przed planowanymterminem ślubu.</li>
                    <li>Należy przynieść:</li>
                    <ul>
                        <li>Dowód osobisty</li>
                        <li>Aktualną metrykę chrztu (o ile osoba nie była chrzczona w tej parafii)</li>
                        <li>Zaświadczenie o bierzmowaniu</li>
                        <li>Dokumenty z Urzędu Stanu Cywilnego</li>
                        <li>Świadectwo z nauki religii z ostatniej klasy (indeks)</li>
                    </ul>
                </ol>
                <p>
                    Zgodnie z przepisami prawa kanonicznego narzeczeni zobowiązani są do udziału w
                    konferencjach przygotowujących do sakramentu małżeństwa (tzw. kurs przedmałżeński).
                </p>
            </div>
            <Footer />
        </>
    )
}

export default Sakramenty