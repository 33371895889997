import Navigation from '../componets/Navigation';
import Footer from '../componets/Footer';

function Galeria() {
    return(
        <>
            <Navigation />
            <div className='article'>
                <div className='title'>Galeria</div>
                <ul>
                    <li><a href='http://galeria.nawiedzeniepiotrowice.pl/parafia/index.html'>Kościół w Piotrowicach</a></li>
                    <li><a href='http://galeria.nawiedzeniepiotrowice.pl/barborka2024/index.html'>Barbórka 2024</a></li>
                    <li><a href='http://galeria.nawiedzeniepiotrowice.pl/kolendowanie2024/index.html'>Kolędowanie 2024</a></li>
                </ul>
            </div>
            <Footer />
        </>
    )
}

export default Galeria