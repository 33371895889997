import './App.css';
import Navigation from './componets/Navigation'
import Footer from './componets/Footer';
import p1 from './imgs/Plakat Boze Narodzenie.jpg'

function App() {
  return (
    <>
      <Navigation />
      <div className='article'>
            <div className='title'>WIZYTACJA KANONICZNA 15 XII 2024</div>
            <p>
                W obrzędzie przywitania Biskupa wizytującego Parafię teraz nastał czas na
                sprawozdanie Proboszcza. „Czym jest parafia? To jednostka administracyjna kościoła, to
                skupisko ludzi wierzących, to punkt na mapie bądź notatka w wyszukiwarce
                internetowej... dla nas - PARAFIA to miejsce, gdzie niebo styka się z ziemią - gdzie
                nieustannie w każdej Mszy Świętej kapłan uprasza u Boga Miłosierdzie - a Bóg jak
                miłosierny Pan oczekuje nas - sługi swoje - byśmy okazywali się wierni w każdej sprawie.
                Dziś gromadzi nas w kościele wyjątkowa okoliczność jaką jest wizytacja kanoniczna ks.
                biskupa w parafii. Jest to czynność apostolska i pasterska. Ks. Biskup jako następca
                apostołów, i jak niegdyś oni sami, odwiedza parafię, by spotkać się z wiernymi i wspólnie
                przeżywać radość chrześcijańskiej wiary. Przyjeżdża by słuchać o radościach, wejść w
                nasze troski, by prosić Boga o błogosławieństwo dla naszego życia.
            </p>
            <p>
                Parafia Nawiedzenia NMP w Piotrowicach erygowana została przed 1325r.. Źródła
                historyczne nie wspominają pierwszej światynii, natomiast „Acta episcopalia” notują, iż bp
                Stanisław Szembek 14 IX 1698r.konsekrował kościół drewniany w miejscowości
                „Piotrowycze”, spłonął on w 1876r. Rok 1895 to rok zakończenia budowy kościoła, w
                którym się znajdujemy, a konsekrowanego 22 V 1897r. przezKsięcia bp Jana Puzynę.
            </p>
            <p>
                Nasza parafia wbrew pozorom nie należy do wielkich, mieszka tu 2580 katolików
                Piotrowic i pobliskich Łowiczek. W tym zaledwie ok. 1000 osób to ci, którzy
                maksymalnie skończyli 20 lat życia, a ponad 1100 osób ma powyżej 50 lat. Od jakiegoś
                czasu daje się zauważyć i u nas pokusę „gaszenia nadziei”, zdezorientowania,
                niepewności. Wielu ludzi nie potrafi już łączyć ewangelicznego przesłania z codziennym
                życiem, wzrasta trudność przeżywania osobistych relacji z Bogiem. Zagraża nam
                obojętność religijna, osłabienie wiary – przez co stajemy się łatwym łupem tego świata o
                czym świadczy obojętność religijna, zagubienie, porzucanie praktyk religijnych. Od
                jakiegoś czasu daje się zauważyć zanik wrażliwości na świętowania Dnia Pańskiego, jak
                pisał św.Jan Paweł II: „...nawet jeśli współczesny człowiek odświętnie się ubierze, już nie
                potrafi swiętować”. To widać i w naszej wspólnocie, gdzie wielu nie uczestniczy
                regularnie we Mszy św. niedzielnej, a pewien procent w ogóle zarzucił praktyki religijne.
                Następuje osłabienie świadomości nierozerwalności małżeństwa, co prowadzi do rozbicia
                rodzin czy też życia w związkach niesakramentalnych. Przyczyn tego stanu dopatrywać się
                można również w braku poczucia grzechu.
            </p>
            <p>
                W niedzielnej Eucharystii uczestniczy 700 – 750 wiernych co stanowi ok 27% - w
                ostatnich dwóch latach liczba ta wzrosła o 100 wiernych. Nie są to jakieś imponujące
                liczby dlatego oprócz normalnej pracy duszpasterskiej wspólnej dla wszystkich parafii
                podejmuję różnorakie działania by przyprowadzić ludzi do kościoła jak np. procesja
                Bożego Ciała, która prowadzi przez drogi parafii, regularne spotkania przygotowujące do
                przyjęcia sakramentów, okresowe liturgiczne spotkania z dziećmi. Ponadto realizujemy
                działania o różnym charakterze by pomóc ludziom spotkać Chrystusa i wsłuchać się w
                Jego naukę. Wśród tych działań dbamy o modlitwę różańcową – Róże Różańcowe od
                1878r., i kult NSPJ – Bractwo NSPJ od 1744r., podejmujemy różne nabożeństwa, formy
                modlitewne jak np. całonocna adoracja Najśw. Sakramentu z inicjatywy Róż
                Różańcowych, konferencje jako katechezę dla dorosłych, formację ministrantów. Parafia
                angażuje się w różnego rodzaju akcje charytatywne i zbiórki: pomoc misjonarzom w
                zakupie środków lokomocji, wsparcie Kuchni Brata Alberta, jak również w różne doraźne
                - dodatkowe zbiórki organizowane w kościele. Sposobem na spotkanie i gromadzenie się
                wokół Chrystusa są też działania o charakterze kulturalnym takie jak: Rodzinne
                Kolędowanie czy wyjazdy, by poznać kulturę i dziedzictwo narodowe, grupa parafian
                rergularnie od wielu lat pielgrzymuje każdego 16-go dnia miesiąca na modlitewne
                czuwanie na Jasnej Górze.
            </p>
            <p>
                Na szczególne podkreślenie zasługuje posługa w konfesjonale i niekończące się
                kolejki zwłaszcza w dniach przedświatecznych, gdzie wspomagają zaproszeni kapłani.
                Skuteczność tych wszystkich działań i owoce tych działań zostawiamy Panu Bogu.
            </p>
            <p>
                Jak widać większość budynków parafialnych ma ponad 130 lat i wszystkie
                domagają się gruntownych remontów. W minionych dwóch latach udało się odnowić
                ogrodzenie świątynii, zainstalować nagłośnienie, odnowić ławki, przeprowadzić remont
                plebanii na jej parterowym poziomie i rozpocząć modernizację i remont dachu naszej
                światynii. Niestety, jak już wspomniałem, nasza Parafia nie należy do bardzo ludnych, a
                korzystający z naszej „infrastruktury” nie zawsze włączają się w jej utrzymanie (za nic i od
                nikogo nie pobieramy żadnych opłat!). Ponadto jak wszyscy wiemy te czasy dla nikogo nie
                są łatwe, dlatego z rozwagą i na chłodno podchodzimy do przygotowania i realizacji prac.
            </p>
            <p>
                Na koniec pragnę bardzo mocno podkreślić, że to wszystko co się u nas dzieje nie
                byłoby możliwe bez cudownych, wspaniałych i ofiarnych Parafian i nie tylko, którzy
                dzielą się z innymi środkami materialnymi, swoim czasem, pracą i zdolnościami.
                Podziwiam ich pobożność, zaangażowanie, pomysłowość i gotowość do poświęceń a także
                umiejętność współpracy z ludźmi i ich piękne podejście do innych. Nieraz też
                doświadczam ich troski o moją osobę. To moje sprawozdanie może jest nieco
                przydługawe, więc przepraszam, ale czuję się zobowiązany, by wypowiedzieć te słowa
                uznania i wdzięczności w kierunku wszystkich angażujących się w życie naszej Parafii tu i
                teraz wobec Księdza Biskupa, bo ci ludzie naprawdę na to zasługują.
            </p>
            <p>
                Proszę Jego Ekscelencję o błogosławieństwo i modlitewne wsparcie dla naszych
                rodzin i całej współnoty u Jezusa, za którego głosem poszedłeś i u Matki Kalwaryjskiej
                pod, której opieką wzrastałeś.
            </p>
            <p className='podpis'>Ks. Tomasz Rajda - proboszcz</p>
        </div>
      <Footer />
    </>
  );
}

export default App;
