import Navigation from '../componets/Navigation'
import Footer from '../componets/Footer'

function Ministranci() {
    return(
        <>
            <Navigation />
            <div className='article'>
                <div className='title'>Ministranci</div>
                <div className='subtitle'>Grafik Niedziela</div>
                <table>
                    <tr><td>I</td><td>II</td></tr>
                    <tr><td>Jakub Zięba</td><td>Bartosz Domider</td></tr>
                    <tr><td>Szymon Zięba</td><td>Dawid Gagracz</td></tr>
                    <tr><td>Kamil Całus</td><td>Szymon Kolasa</td></tr>
                    <tr><td>Jakub Zimnal</td><td>Filip Boba</td></tr>
                    <tr><td>Jan Zimnal</td><td>Kacper Knutel</td></tr>
                    <tr><td>Tomasz Wiśniecki</td><td>Patryk Lipowski</td></tr>
                    <tr><td></td><td>Karol Domasik</td></tr>
                </table>
                <div className='subtitle'>Grafik Tygodniowy</div>
                <ul>
                    <li>Poniedziałek godz. 18.00</li>
                    <li>Jakub Zięba</li>
                    <li>Szymon Zięba</li>
                    <li>Dawid Gagracz</li>
                    <li>Kamil Całus</li>
                </ul>
                <ul>
                    <li>Wtorek godz. 7.00</li>
                    <li>Szymon Kolasa</li>
                    <li>Filip Boba</li>
                    <li>Karol Domasik</li>
                    <li>Patryk Lipowski</li>
                </ul>
                <ul>
                    <li>Środa godz. 18.00</li>
                    <li>Kacper Knutel</li>
                    <li>Tomasz Wiśniecki</li>
                    <li>Jakub Zimnal</li>
                    <li>Jan Zimnal</li>
                </ul>
                <ul>
                    <li>Czwartek godz. 7.00</li>
                    <li>Kacper Knutel</li>
                    <li>Tomasz Wiśniecki</li>
                    <li>Bartosz Domider</li>
                    <li>Jakub Zimnal</li>
                    <li>Jan Zimnal</li>
                </ul>
                <ul>
                    <li>Piątek godz. 7.00/18.00</li>
                    <li>Szymon Kolasa</li>
                    <li>Filip Boba</li>
                    <li>Bartosz Domider</li>
                    <li>Karol Domasik</li>
                    <li>Patryk Lipowski</li>
                </ul>
                <ul>
                    <li>Sobota godz. 7.00/18.00</li>
                    <li>Jakub Zięba</li>
                    <li>Szymon Zięba</li>
                    <li>Dawid Gagracz</li>
                    <li>Kamil Całus</li>
                </ul>
                <div className='subtitle'>Modlitwa przed służeniem</div>
                <p>
                    Oto za chwilę przystąpię "Do Ołtarza Bożego, do
                    Boga, który rozwesela młodość moją".
                    Do świętej przystępuję służby. Chcę ją dobrze
                    pełnić.
                    Proszę Cię, Panie Jezu, o łaskę skupienia,
                    by myśli moje były przy Tobie,
                    by oczy moje były zwrócone na ołtarz,
                    a serce moje oddane tylko Tobie. Amen.
                </p>
                <br />
                <div className='subtitle'>Modlitwa po służeniu</div>
                <p>
                    Boże, którego dobroć powołała mnie do Twej
                    służby, spraw, bym uświęcony uczestnictwem w
                    Twych tajemnicach przez dzień dzisiejszy i całe
                    me życie, szedł tylko drogą zbawienia. Przez
                    Chrystusa, Pana naszego. Amen
                </p>
            </div>

            <Footer />
        </>
    )
}

export default Ministranci