import '../App.css';
import Navigation from '../componets/Navigation';
import Footer from '../componets/Footer';
import { globalVar } from '../globalVar';

function data(i: number)
{
    var dzien = 0;
    var miesiac= 0;
    var miesiacTyp = 0; 
    var rok= 0;
    var miesiacOut= "";
    var dzientyg= "";
    globalVar.map((map,index) => {
        dzien = parseInt(map.dzien);
        miesiac = parseInt(map.miesiac);
        rok = parseInt(map.rok);
        return 0;
    })
    if (i === 1) {dzientyg = "Poniedziałek"}
    if (i === 2) {dzientyg = "Wtorek"}
    if (i === 3) {dzientyg = "Środa"}
    if (i === 4) {dzientyg = "Czwartek"}
    if (i === 5) {dzientyg = "Piątek"}
    if (i === 6) {dzientyg = "Sobota"}
    if (i === 7) {dzientyg = "Niedziela"}
    if (i === 9) {dzientyg = ""}
    if(miesiac === 1 || miesiac === 3 || miesiac === 5 || miesiac === 7 || miesiac === 8 || miesiac === 10 || miesiac === 12)
    {
        miesiacTyp = 0;
    }
    if(miesiac === 4 || miesiac === 6 || miesiac === 9 || miesiac === 11)
    {
        miesiacTyp = 1;
    }
    if(miesiac === 2 && rok%4 !== 0)
    {
        miesiacTyp = 2;
    }
    if(miesiac === 2 && rok%4 === 0)
    {
        miesiacTyp = 3;
    }
    if(miesiacTyp === 0)
    {
        if(dzien + i > 31)
        {
            miesiac = miesiac + 1;
            dzien = (dzien + i) - 31
            if(miesiac < 10)
            {
                miesiacOut = "0"+String(miesiac);
            }
            else
            {
                miesiacOut=String(miesiac);
            }
            return <tr><td>{dzientyg} {dzien}.{miesiacOut}</td><td></td></tr>
        }
        else
        {
            dzien = dzien + i
            if(miesiac < 10)
            {
                miesiacOut = "0"+String(miesiac);
            }
            else
            {
                miesiacOut=String(miesiac);
            }
            return <tr><td>{dzientyg} {dzien}.{miesiacOut}</td><td></td></tr>

        }
    }
    if(miesiacTyp === 1)
    {
        if(dzien + i > 30)
        {
            miesiac = miesiac + 1;
            dzien = (dzien + i) - 30
            if(miesiac < 10)
            {
                miesiacOut = "0"+String(miesiac);
            }
            else
            {
                miesiacOut=String(miesiac);
            }
            return <tr><td>{dzientyg} {dzien}.{miesiacOut}</td><td></td></tr>
        }
        else
        {
            dzien = dzien + i
            if(miesiac < 10)
            {
                miesiacOut = "0"+String(miesiac);
            }
            else
            {
                miesiacOut=String(miesiac);
            }
            return <tr><td>{dzientyg} {dzien}.{miesiacOut}</td><td></td></tr>

        }
    }
    if(miesiacTyp === 2)
    {
        if(dzien + i > 28)
        {
            miesiac = miesiac + 1;
            dzien = (dzien + i) - 28
            if(miesiac < 10)
            {
                miesiacOut = "0"+String(miesiac);
            }
            else
            {
                miesiacOut=String(miesiac);
            }
            return <tr><td>{dzientyg} {dzien}.{miesiacOut}</td><td></td></tr>
        }
        else
        {
            dzien = dzien + i
            if(miesiac < 10)
            {
                miesiacOut = "0"+String(miesiac);
            }
            else
            {
                miesiacOut=String(miesiac);
            }
            return <tr><td>{dzientyg} {dzien}.{miesiacOut}</td><td></td></tr>

        }
    }
    if(miesiacTyp === 3)
    {
        if(dzien + i > 29)
        {
            miesiac = miesiac + 1;
            dzien = (dzien + i) - 30
            if(miesiac < 10)
            {
                miesiacOut = "0"+String(miesiac);
            }
            else
            {
                miesiacOut=String(miesiac);
            }
            return <tr><td>{dzientyg} {dzien}.{miesiacOut}</td><td></td></tr>
        }
        else
        {
            dzien = dzien + i
            if(miesiac < 10)
            {
                miesiacOut = "0"+String(miesiac);
            }
            else
            {
                miesiacOut=String(miesiac);
            }
            return <tr><td>{dzientyg} {dzien}.{miesiacOut}</td><td></td></tr>

        }
    }
}


function Intencje() {
  return (
    <>
      <Navigation />
      <div className="article">
            <div className='title'>Intencje Mszalne  na  27.01 - 02.02</div>
            <table>
                {data(1)}
                <tr><td>18:00</td><td>++ Józef Zofia Szczelina, Anna c. teściowie</td></tr>
                {data(2)}
                <tr><td>7:00</td><td>++ Franciszek Rozalia Frączek, rodzice</td></tr>
                {data(3)}
                <tr><td>18:00</td><td>+ Jadwiga Zielińska</td></tr>
                {data(4)}
                <tr><td>7:00</td><td>++ Mieczysłąw Hałat, Jan Rozalia Mamoń</td></tr>
                {data(5)}
                <tr><td>18:00</td><td>++ Stanisław Barbara Zając</td></tr>
                {data(6)}
                <tr><td>7:00</td><td>++ Stanisław Zofia Frączek</td></tr>
                <tr><td>18:00</td><td>++ Adam rocz. Stanisław Anteccy</td></tr>
                {data(7)}
                <tr><td>7:00</td><td>1. W int. Róży Różańcowej</td></tr>
                <tr><td></td><td>2. + Stanisława Nowak (od wnuczki Beaty)</td></tr>
                <tr><td>11:00</td><td>1. Za parafian</td></tr>
                <tr><td></td><td>2. + Andrzej Krzyścin (od żony i córki)</td></tr>
            </table>
            </div>
      <Footer />
    </>
  );
}

export default Intencje;
