import './Footer.css'
import Navbar from 'react-bootstrap/Navbar';
function Footer(){
    return(
        <>
        <Navbar>
            <footer>
                <div className="nazwa">
                    <p>© 2024 Parafia pod wezwaniem Nawiedzenia Najświętszej Maryji Panny w Piotrowicach</p>
                    <p>Piotrowice ul.Kościelna 32-641 Piotrowice</p>
                </div>
                <div className="dane">     
                    <p>Telefon +48 33 841 45 69</p>
                    <p>Numer Konta 78 8136 0000 0022 6279 2000 0010</p>
                    <p>Email: kancelaria@nawiedzeniepiotrowice.pl</p>
                </div>
            </footer>
        </Navbar>
    </>
    );
}

export default Footer