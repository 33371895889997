import '../App.css'
import Navigation from '../componets/Navigation'
import Footer from '../componets/Footer'
import './repeat.css'

function Podatek(){
    return (
        <>
            <Navigation />
            <div className='article'>
                <div className='title'>Odliczenia od podstawy opodatkowania podatkiem dochodowym o kwotę przekazanych darowizn na cele kultu religijnego</div>
                <p>
                    Obowiązujący aktualnie system prawa podatkowego daje podatnikom, zarówno
                    osobom fizycznym jak i osobom prawnym, dokonującym darowizn na cele kultu
                    religijnego, prawo do obniżenia podstawy opodatkowania podatkiem dochodowym o
                    kwotę przekazanych darowizn na cele kultu religijnego. Zgodnie z art. 26 ust. 1 pkt 9 lit. b
                    ustawy o podatku dochodowym od osób fizycznych, podstawę opodatkowania stanowi
                    dochód po odliczeniu między innymi darowizn na cele kultu religijnego - łącznie do
                    wysokości 6% dochodu (odpowiednio 6% przychodu wykazywanego w zeznaniu PIT-28).
                    Stosownie do art. 18 ust. 1 pkt 7 ustawy o podatku dochodowym od osób prawnych,
                    podstawę opodatkowania stanowi dochód po odliczeniu między innymi darowizn na cele
                    kultu religijnego - łącznie do wysokości nieprzekraczającej 10% dochodu wykazywanego
                    w zeznaniu CIT-8. Odliczenia z tytułu darowizn, w tym darowizn na cele kultu

                    religijnego, można dokonać, jeżeli wysokość darowizny pieniężnej jest udokumentowana
                    dowodem wpłaty na rachunek bankowy obdarowanego, a w przypadku darowizny innej
                    niż pieniężna - dokumentem, z którego wynika wartość tej darowizny oraz oświadczeniem
                    obdarowanego o jej przyjęciu. Jeżeli przedmiotem darowizny są towary opodatkowane
                    podatkiem od towarów i usług, za kwotę darowizny uważa się wartość towaru wraz z
                    podatkiem od towarów i usług ustalonym zgodnie z odrębnymi przepisami. Zarówno
                    ustawa o podatku dochodowym od osób fizycznych jak i ustawa o podatku dochodowym
                    od osób prawnych, nie zawiera definicji pojęcia „kult religijny”.
                    Według Słownika języka polskiego /PWN Warszawa 1978r./, kult religijny oznacza
                    „zewnętrzny aspekt religii w odróżnieniu od teoretycznej doktryny; ogół obrzędów
                    religijnych jakiejś religii, całokształt czynności religijnych”. W myśl podanej definicji,
                    celom kultu religijnego służą zarówno działania, które odnoszą się do praktyk, obrzędów i
                    uroczystości religijnych, jak też dotyczące obiektów sakralnych i związanej z nimi
                    infrastruktury.
                    W orzecznictwie sądów administracyjnych pojęcie „cele kultu religijnego” należy
                    rozumieć jako środki przeznaczone dla kościołów, związków religijnych i kościelnych
                    osób prawnych. W przypadku Kościoła Katolickiego, podmioty na rzecz których możliwe
                    jest przekazanie darowizn podlegających odliczeniu od dochodu wymienia ustawa z dnia
                    17 maja 1989 r. o stosunku Państwa do Kościoła Katolickiego w Rzeczpospolitej Polskiej
                    (Dz. U. Nr 29, poz. 154 ze zm.). Zgodnie z tą ustawą, w skład struktury organizacyjnej
                    Kościoła Katolickiego wchodzą takie osoby prawne jak: diecezje, parafie, Caritas Polska,
                    zakony.
                </p>
            </div>
            <Footer />
        </>
)}

export default Podatek