import '../App.css';
import Navigation from '../componets/Navigation'
import Footer from '../componets/Footer';
import { globalVar } from '../globalVar';
function Ogloszenia() {
  return (<>
        <Navigation />
        <div className='article'>
            <div className='title'>Ogłoszenia {globalVar.map((map,index) => {return map.niedziela})} </div>
            <p>Kalendarz Liturgiczny</p>
            <ul>
                <li>wtorek - wspomnienie św.Tomasza z Akwinu, kapłana doktora Kościoła</li>
                <li>piątek - wspomnienie św.Jana Bosko, kapłana</li>
            </ul>
            <p>
                W środę o godz. 18.00 Msza św. i Nowenna do MBNP. Przed Mszą św. różańiec
                prowadzony przez VI Różę w intencji chorych i cierpiących.
            </p>
            <p>
                I sobota miesiąca – Dzień Patronalny Róż Różańcowych:
                o godz.17.30 różaniec prowadzony przez Róże Różańcowe;
                o godz.18.00 Msza św. z konferencją dla Róż Różańcowych i nabożeństwo ku czci
                Niepokalanego Serca NMP.
            </p>
            <p>
                W przyszłą niedzielę 2 lutego przeżywać będziemy Święto Ofiarowania Pańskiego
                (Matki Bożej Gromnicznej), na Mszy św. o godz. 11.00 dzieci przygotowujące się do
                przyjęcia po raz pierwszy Komunii św. odnowią przyrzeczenia chrzcielne.
                Po Mszy św. o godz. 11.00, wystąpi w kościele Franciszek Makuch (wnuk piotrowickiego
                organisty) – solista opery i operetki krakowskiej, w swoim występie zaśpiewa zapomniane
                i mało znane kolędy, jak również kilka „krakowskich” piosenek. Serdecznie zapraszam na
                to spotkanie.
            </p>
            <p>
                Dziękuję za składane dziś ofiary na prowadzone w naszej wspólnocie prace
                inwestycyjne. Za każdy gest wsparcia – Bóg zapłać!
            </p>
            <p>
                Dziękuję za sprzątanie kościoła na dzisiejszą niedzielę i ofiarę 100zł, na przyszłą
                niedzielę troskę o świątynię powierzamy rodzinom:
            </p>
            <ul>
                <li>Sebastiana i Patrycji Szkudlarek</li>
                <li>Mariusza i Katarzyny Sandacz</li>
                <li>Tomasza i Jolanty Ulczok</li>
                <li>Stanisławy Wędrzyk</li>
                <li>Czesława i Heleny Miętka    </li>
            </ul>
            <p>
                Dziękuję za sprzątanie kaplicy na dzisiejszą niedzielę i ofiarę 100zł, na przyszłą
                niedzielę troskę o kaplicę powierzamy rodzinom:
            </p>
            <ul>
                <li>Stanisława i Sabiny Bik</li>
                <li>Łukasza i Edyty Śliwińskich</li>
                <li>Piotra Pardygała</li>
                <li>Dawida i Barbary Rogulskich</li>
            </ul>
            <p>
                Wizyta duszpasterska
            </p>
            <ul>
                <li>27.01 poniedziałek godz.8.00 – Piotrowice ul.Andrychowska (od rodz.Zajas nr 246 do rodz.Piotrowskich nr 354)</li>
                <li>28.01 wtorek godz.8.00 – Piotrowice ul.Andrychowska (od rodz.Żurek nr 360 do rodz.Zięba nr 351)</li>
            </ul>
            <p>W minionym tygodniu do wieczności odszedł:
śp. Jan Domider z ul.Andrychowskiej, prośmy o łaskę nieba dla niego.

Dobry Jezu, a nasz Panie, daj mu wieczne spoczywanie.</p>
        </div>
        <Footer />
  </>
  );
}

export default Ogloszenia;
